import { Injectable, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/interfaces/User';
import { UserService } from './user.service';
import { Company } from 'src/app/interfaces/Company';
import { Tag } from 'src/app/interfaces/Tag';
import { CompanyService } from './company.service';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    private db: firebase.firestore.Firestore;
    public userstate: number = 0;

    constructor(
        private us: UserService,
        private cs: CompanyService
    ) {
        firebase.initializeApp(environment.firebase);
        this.db = firebase.firestore();
    }

    /**
     * Login to firebase service
     * @param email User email
     * @param pwd User password
     */
    public Login(email: string, pwd: string): Promise<string> {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, pwd).then((res) => {
                firebase.auth().currentUser.getIdToken().then((token) => {
                    this.GetUserData(email).then((usr) => {
                        //console.log(usr);
                        resolve(token);
                    });
                });
            }).catch((err) => {
                console.log(err);
                reject(err.message);
            });
        });
    }

    /**
     * Logout from firebase service
     */
    public Logout(): Promise<void> {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve();
            }).catch(() => {
                reject();
            });
        });
    }

    /**
     * GetUserdata from email
     * @param email User email
     */
    public async GetUserData(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection("users").where("email", "==", email).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.us.User = { id: doc.id, ...doc.data() as any };
                    this.us.gotIt = true;
                });
                resolve(this.us.User);
            }).catch((err) => {
                console.log(err);
                reject();
            });
        });
    }

    /**
     * Get users list
     */
    public GetUsersList(): Promise<any> {
        let users_list: Array<User> = [];
        return new Promise((resolve, reject) => {
            this.db.collection('users').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    users_list.push({ id: doc.id, ...doc.data() as any });
                });
                users_list.forEach((usr) => {
                    usr.company.get().then((doc) => {
                        usr.company = { id: doc.id, ...doc.data() as any };
                    });
                });
                resolve(users_list);
            }).catch((err) => {
                console.log("GetUsersList ERROR: ", err);
            });
        });
    }

    /**
     * Get a list of all existing companies
     */
    public GetCompaniesList(): Promise<any> {
        let companies_list: Array<Company> = [];
        return new Promise((resolve, reject) => {
            this.db.collection("companies").get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    companies_list.push({ id: doc.id, ...doc.data() as any });
                });
                resolve(companies_list);
            }).catch((err) => {
                console.log("Companies List ERROR: ", err);
                reject();
            });
        });
    }

    /**
     * Get users list
     */
    public GetCompanyUsersList(reference: firebase.firestore.DocumentReference): Promise<any> {
        let users_list: Array<User> = [];
        return new Promise((resolve, reject) => {
            this.db.collection('users').where("company", "==", reference).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    users_list.push({ id: doc.id, ...doc.data() as any });
                });
                users_list.forEach((usr) => {
                    usr.company.get().then((doc) => {
                        usr.company = { id: doc.id, ...doc.data() as any };
                    });
                });
                resolve(users_list);
            }).catch((err) => {
                console.log("GetUsersList ERROR: ", err);
            });
        });
    }

    /**
     * Get a list of all existing companies
     */
    public GetCustomersList(parentId): Promise<any> {
        let companies_list: Array<Company> = [];
        return new Promise((resolve, reject) => {
            this.db.collection("companies").where("parent", "==", parentId).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    companies_list.push({ id: doc.id, ...doc.data() as any });
                });
                resolve(companies_list);
            }).catch((err) => {
                console.log("Companies List ERROR: ", err);
                reject();
            });
        });
    }

    /**
     * Get a company from its reference
     * @param refernce 
     */
    public GetCompany(refernce: firebase.firestore.DocumentReference): Promise<Company> {
        let company: Company = {} as any;
        return new Promise((resolve, reject) => {
            refernce.get().then((querySnapshot) => {
                company = { id: querySnapshot.id, ...querySnapshot.data() as any };
                resolve(company);
            })
        });
    }

    /**
     * Get a tag list of a specific company
     * @param id Company id
     */
    public GetTagListByCompany(id: string): Promise<any> {
        let tag_list: Array<Tag> = [];
        return new Promise((resolve, reject) => {
            this.db.collection('companies').doc(id).collection('tags').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    tag_list.push({ id: doc.id, ...doc.data() as any });
                });
                resolve(tag_list);
            }).catch((error) => {
                console.log("GetTagListByCompany ERROR: ", error);
                reject();
            });
        });
    }

    /**
     * Move a tag to trash
     * @param tagId tag id to delete
     */
    public DeleteTag(tagId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.db.collection('companies').doc(this.cs.Company.id).collection('tags').doc(tagId).get().then((doc) => {
                this.db.collection('trashtag').doc(doc.id).set(doc.data()).then(() => {
                    this.db.collection('companies').doc(this.cs.Company.id).collection('tags').doc(tagId).delete().then(() => {
                        this.UpdatePlanUse(this.cs.Company.id, 1).then(() => {
                            resolve();
                        });
                    }).catch((err) => {
                        console.log("Tag Deleting ERROR: ", err);
                        reject();
                    });
                }).catch((err) => {
                    console.log("Can't add tag to trash ERROR: ", err);
                    reject();
                });
            });
        });
    }

    /**
     * Get Trashcan of a company
     * @param companyId company id
     */
    public GetTrashcan(companyId: string): Promise<any> {
        let trashtag_list: Array<Tag> = [];
        return new Promise((resolve, reject) => {
            this.db.collection('trashtag').where('company.id', '==', companyId).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    trashtag_list.push({ id: doc.id, ...doc.data() as any });
                });
                resolve(trashtag_list);
            });
        });
    }

    /**
     * Create new tag
     * @param newTag 
     */
    public CreateTag(newTag: Tag): Promise<string> {
        newTag.tagType = 0;//set qrcode as default
        // newTag.user.company = undefined;
        return new Promise((resolve, reject) => {
            if (newTag.company.plan == 2 && newTag.company.use >= 5)
                reject("Numero massimo di tag raggiunto");
            else {
                this.db.collection('companies').doc(newTag.company.id).collection('tags').add(newTag).then((docref) => {
                    this.UpdatePlanUse(newTag.company.id, 0).then(() => {
                        resolve(docref.id);
                    });
                }).catch((err) => {
                    console.log("Create tag ERROR: ", err);
                    reject();
                })
            }
        });
    }

    /**
     * Edit tag
     * @param selectedTag  tag to edit
     */
    public EditTag(selectedTag: Tag): Promise<void> {
        return new Promise((resolve, reject) => {
            this.db.collection('companies').doc(selectedTag.company.id).collection('tags').doc(selectedTag.id).set(selectedTag).then(() => {
                resolve();
            }).catch((err) => {
                console.log("Edit tag ERROR: ", err);
                reject();
            });
        });
    }

    /**
     * Delete tag forever
     * @param selectedTag Tag to delete
     */
    public DeleteTagForever(selectedTag: Tag): Promise<void> {
        return new Promise((resolve, reject) => {
            this.db.collection('trashtag').doc(selectedTag.id).delete().then(() => {
                resolve();
            }).catch((err) => {
                console.log('Delete tag forever ERROR: ', err);
                reject();
            });
        });
    }

    /**
     * Restore a tag from trashcan
     * @param selectedTag Tag to restore
     */
    public RestoreTag(selectedTag: Tag): Promise<string> {
        return new Promise((resolve, reject) => {
            if (selectedTag.company.plan == 2 && selectedTag.company.use >= 5)
                reject("Numero massimo di tag raggiunto");
            else {
                this.db.collection('companies').doc(selectedTag.company.id).collection('tags').doc(selectedTag.id).set(selectedTag).then(() => {
                    this.DeleteTagForever(selectedTag).then(() => {
                        this.UpdatePlanUse(selectedTag.company.id, 0).then(() => {
                            resolve("done");
                        });
                    }).catch((err) => {
                        reject();
                    });
                }).catch((err) => {
                    reject();
                });
            }
        });
    }

    /**
     * Create a new company
     * @param newCompany Company to create
     */
    public CreateCompany(newCompany: Company): Promise<void> {
        return new Promise((resolve, reject) => {
            this.db.collection('companies').add(newCompany).then((res) => {
                resolve();
            }).catch((err) => {
                console.log("Create company ERROR: ", err);
                reject();
            });
        });
    }

    // /**
    //  * Create a new cuatomer
    //  * @param newCompany Company to create
    //  */
    // public CreateCustomer(newCompany: Company): Promise<void> {
    //     return new Promise((resolve, reject) => {
    //         this.db.collection('companies').add(newCompany).then((res) => {
    //             resolve();
    //         }).catch((err) => {
    //             console.log("Create company ERROR: ", err);
    //             reject();
    //         });
    //     });
    // }

    /**
     * Get companies reference
     */
    public GetCompaniesReference(): Promise<any> {
        let companiesRef: Array<{ ref, name: string }> = [];
        return new Promise((resolve, reject) => {
            this.db.collection('companies').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    companiesRef.push({ ref: doc.ref, name: doc.data().name });
                });
                resolve(companiesRef);
            });
        });
    }

    /**
     * Create new user
     * @param newUser User to create
     * @param pwd User's password
     */
    public CreateUser(newUser: User, pwd: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.db.collection('users').add(newUser).then((res) => {
                var secondaryApp: any = {} as any;
                secondaryApp = firebase.initializeApp(environment.firebase, "Secondary");//necessaria perchè la funzione createUserWithEmailAndPassword esegue anche il login con il nuovo utente
                secondaryApp.auth().createUserWithEmailAndPassword(newUser.email, pwd).then(() => {
                    console.log(firebase.auth().currentUser);
                    secondaryApp.auth().signOut();
                    firebase.app("Secondary").delete();
                    resolve();
                })
            }).catch((err) => {
                console.log("Create user ERROR: ", err);
                reject();
            });
        });
    }

    /**
     * Update company plan use
     * @param companyId company id
     * @param operator 0 to increment; 1 to decrement
     */
    private UpdatePlanUse(companyId: string, operator: number): Promise<void> {
        return new Promise((resolve, reject) => {
            if (operator == 0) {
                this.db.collection('companies').doc(companyId).update({ use: firebase.firestore.FieldValue.increment(1) }).then(() => {
                    resolve();
                });
            } else {
                this.db.collection('companies').doc(companyId).update({ use: firebase.firestore.FieldValue.increment(-1) }).then(() => {
                    resolve();
                });
            }
        });
    }
}